@import "node_modules/bootstrap/scss/bootstrap.scss";

.container-fluid {
  height: 100%;
}

.left-side {
  left: 0;
  top: 0;
  height: 100vh;
  background: linear-gradient(185.66deg, rgba(31, 114, 209, 0.16) 0%, rgba(31, 114, 209, 0) 99.13%);
}

.right-side {
  font-family: Helvetica Neue;
  font-style: normal;
  padding-left: 10.75%;
  height: 100vh;

  & .content {
    min-height: calc(100vh - 78px);

    & .image {
      padding-top: 154px;
      mix-blend-mode: normal;
      opacity: 0.75;
    }
    & .image-mobile {
      margin-top: 44px;
      mix-blend-mode: normal;
      opacity: 0.75;
    }

    & .titles {
      padding-left: 0;
      h1 {
        text-align: left;
        margin-left: 0;
        margin-top: 47px;

        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
        color: #333333;
      }
      p {
        text-align: left;
        margin-top: 16px;
        margin-left: 0;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: #666666;
      }
      h3 {
        text-align: left;
        margin-top: 28px;
        margin-bottom: 0;
        font-weight: bold;
        font-size: 12px;
        line-height: 15px;
        color: #333333;
      }
    }
    & .app-buttons {
      margin-top: 12px;
      & a {
        display: block;
        margin: 8px 8px 0 0;
        height: 40px;
        float: left;
        border: 1px solid #E8E8E8;
        border-radius: 7px;
        padding: 7px 9px;
        font-size: 14px;
        line-height: 18px;
        background-color: #FFFFFF;
        color: #333333;

        &.not-displayed {
          display: none;
        }
      }
      & a:hover {
        opacity: 0.9;
        text-decoration: none;
      }
      & a:active {
        opacity: 0.6;
        text-decoration: none;
      }
      & .link-text {
        position: relative;
        top: 1px;
        margin-left: 3px;
        color: #333333;
      }
      & .link-image {
          height: 24px;
          position: relative;
      }
      & .default-platform {
        background-color: #333333;
        & .link-text {
          color: #FFFFFF;
        }
      }
      & .macosx-link {
        & .link-image {
          top: 1px;
        }
      }
      & .ipad_os-link {
        & .link-image {
          top: -2px;
        }
      }
    }
    & .show-platforms-link {
      padding-bottom: 42px;
      font-weight: normal;
      font-size: 13px;
      line-height: 16px;
      margin-top: 24px;
      & a {
        color: #438DE2;
      }
    }
  }

  & .footer {
    height: 78px;
    width: 129px;
  }
  & .footer-mobile {
    margin-top: 39px;
  }
  & .footer p, .footer-mobile p {
    width: 150px;
    height: 16px;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    text-align: left;
    color: #C0C0C0;
    padding-bottom: 62px;
  }
}

